import "bootstrap-dialog";
import Cookies from "js-cookie";
import Input from "src/admin-modules/input.adm";
import Component from "can/component/";
import "can/view/stache/";
import ViewModel from "./login.vm";


Component.extend({
    tag: "hos-login",
    template: can.view("src/components/login/login.template.stache"),
    viewModel: ViewModel,
    events: {
        inserted($el) {

            let host = window.location.hostname;
            const logoSrc = "/branding/applicationLogo?image=header&" + new Date().getTime();
            if (host.includes('azuga')) {
                let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
                link.type = 'image/x-icon';
                link.rel = 'shortcut icon';
                link.href = "src/assets/images/favicon.png";
                document.getElementsByTagName('head')[0].appendChild(link);
            } else {
                let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
                link.type = 'image/x-icon';
                link.rel = 'shortcut icon';
                link.href = logoSrc;
                document.getElementsByTagName('head')[0].appendChild(link);
            }

            const $body = $el.parent().parent();

            Input.init($body).activate();
            Cookies.remove("token", {
                path: "/",
                secure: true,
                sameSite: "None",
            });

            Cookies.remove("JSESSIONID", {
                path: "/",
                secure: true,
                sameSite: "None",
            });

            Cookies.remove("RefreshRate", {
                path: "/",
                secure: true,
                sameSite: "None",
            });

            Cookies.remove("credentials", {
                path: "/",
                secure: true,
                sameSite: "None",
            });

            Cookies.remove("VT-On-Behalf-Of", {
                path: "/",
                secure: true,
                sameSite: "None",
            });

            sessionStorage.removeItem('authToken');
            if (Cookies.get("unauthorized")) {
                Cookies.remove("unauthorized", {
                    path: "/",
                    secure: true,
                    sameSite: "None",
                });
            }
        },
        ".select-language click": function ($el) {
            const selectedLang = $($el).attr("code");

            Cookies.set("preferredLanguage", selectedLang, {
                path: "/",
                secure: true,
                sameSite: "None",
            });
            this.viewModel.attr("lang", selectedLang);
        }
    }
});
