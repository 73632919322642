﻿import "can/construct/";
import "lodash";
import french from "src/locale/fr.json";
import spanish from "src/locale/es.json";
import serbian from "src/locale/rs.json";


function getTranslations( arr ) {
    "use strict";
    var tmpArr = {};

    for ( var i in arr ) {
        if ( arr[ i ] ) {
            tmpArr = _.mergeWith( tmpArr, arr[ i ] );
        }
    }

    return tmpArr;
}

// Initiate namespace
can.Construct("Locale", {
    init:function() {
        "use strict";

        let self = this;

        if ( typeof navigator !== "undefined" && typeof navigator.language !== "undefined" ) {
            if ( _.startsWith( navigator.language, "fr" ) ) { self.lang = "fr"; self.systemLang = "fr"; }
            if ( _.startsWith( navigator.language, "es" ) ) { self.lang = "es"; self.systemLang = "es"; }
            if ( _.startsWith( navigator.language, "rs" ) ) { self.lang = "rs"; self.systemLang = "rs"; }
        }

        if ( !self.enableTranslations ) { self.lang = "en"; }
    },
    enableTranslations: true,
    lang: "en",
    systemLang: "en",
    languages: {
        en: {
            code: "en",
            name: "English (United States)",
            translations: {}
        },
        es: {
            code: "es",
            name: "Español (Estados Unidos)",
            translations: getTranslations( spanish )
        },
        fr: {
            code: "fr",
            name: "Français (Canada)",
            translations: getTranslations( french )
        },
        rs: {
            code: "rs",
            name: "Serbian",
            translations: getTranslations( serbian )
        }
    },
    additionalTranslations: {
        es: {},
        fr: {},
        en: {},
        rs: {}
    },
    removeAdditionalTranslations: {},
    getLangName: function( langCode ) {

        "use strict";

        if ( typeof langCode === "undefined" ) { langCode = this.lang; }

        if ( typeof this.languages[ langCode ] === "undefined" ) { langCode = "en"; }

        return this.languages[ langCode ].name;

    },

    setCookieLang: function( selectedLang ) {

        "use strict";

        Cookies.set("preferredLanguage", selectedLang, {
            path: "/",
            domain: ''
        });
    },
    getCookieLang: function( overrideLang ) {

        "use strict";

        var cookieLang = this.lang;

        if ( Cookies.get( "preferredLanguage" ) ) {
            cookieLang = Cookies.get( "preferredLanguage" );
        }

        if ( overrideLang ) { this.lang = cookieLang; }

        return cookieLang;
    },

    translate: function( str, originalLang, translateToLang ) {

        "use strict";

        let self = this,
            translatedStr = str,
            originalTranslations, translatedList,
            preferredLanguage = Cookies.get( "preferredLanguage" );

        // Just in case the cookies will be deleted change the preferred language to english
        if (preferredLanguage != "en" && preferredLanguage != "es" && preferredLanguage != "fr" && preferredLanguage != "rs") {
            preferredLanguage = "en";
        }

        if (!self.enableTranslations ) { 
            return str; 
        }

        if (typeof( originalLang) === "undefined") {
            originalLang = "en";
        }

        if (typeof( translateToLang) === "undefined") {
            translateToLang = preferredLanguage;
        }

        // get translation list of the original language
        originalTranslations = self.languages[ originalLang ].translations;

        // get translation list of translated language
        translatedList = self.languages[ translateToLang ].translations;

        // most additional translations are for help pages, much better to separate their translations from the pages
        if ( typeof this.additionalTranslations[ originalLang ] !== "undefined" && 
            this.additionalTranslations[ originalLang ] ) {
            _.mergeWith( originalTranslations, this.additionalTranslations[ originalLang ] );
        }

        if ( typeof this.additionalTranslations[ translateToLang ] !== "undefined" && 
            this.additionalTranslations[ translateToLang ] ) {
            _.mergeWith( translatedList, this.additionalTranslations[ translateToLang ] );
        }

        // original language is English then just get translated value
        if ( originalLang === "en" ) {
            if ( typeof translatedList[ str ] !== "undefined" && _.trim( translatedList[ str ] )  !== "" ) { translatedStr = translatedList[ str ]; }
        } else {
            // if original language is not enalish then get english key value from the original translations
            var transKey = _.findKey( originalTranslations, function( o ) { return _.trim( o ) === _.trim( str ); });
            if ( transKey ) {
                // set English key value as the translated string
                if ( translateToLang === "en" ) {
                    translatedStr = transKey;
                } else {
                    if ( typeof translatedList[ transKey ] !== "undefined" && _.trim( translatedList[ str ] ) !== "" ) { translatedStr = translatedList[ transKey ]; }
                }
            }
        }

        return translatedStr;

    }
}, {});


can.stache.registerHelper( "translate", function( str ) {
    "use strict";

    var s = "";

    if ( typeof str === "function" ) {
        s = str();
    } else {
        s = str;
    }

    return Locale.translate( s );
});

can.stache.registerHelper( "translateHTML", function( options ) {
    "use strict";

    var s = options.fn().textContent;

    var div = document.createElement("div");
    div.appendChild( options.fn().cloneNode( true ) );

    s = div.innerHTML;

    return can.frag( Locale.translate( s ) );
});
