import "can/view/stache/";
import "src/components/login/login.component";
import "node-waves";
import "src/helpers/translations";
import "js-cookie";


(function( $ ) {
    "use strict";
    var $loginBox = $("#login-box");
    $loginBox.html( can.view("login.stache") );
    $loginBox = null;
}( can.$ ));
